import React from "react";
import { Form, Input, Button, Row, Col, Container } from "reactstrap";
import { navigate } from "gatsby";

function Search({ targeturl = "/events?search=" }) {
  const handleChange = (e) => {
    e.preventDefault();
    navigate(targeturl + e.target.searchinput.value);
  };

  return (
    <Form onSubmit={handleChange}>
      <Container>
        <Row>
          <Col md={8}>
            <Input
              type="search"
              placeholder="Search events!"
              name="searchinput"
            />
          </Col>
          <Col md={4}>
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

export default Search;
