import React from "react";
import "./default.css";
import Header from "/src/components/helpers/Header/Header";
import ModeToggle from "/src/components/helpers/ModeToggle/ModeToggle"
export const Layout = ({ currentLang, children }) => {
  return (
    <div className="app-wrapper">
      <Header currentLang={currentLang} />
      {children}
      <ModeToggle />
    </div>
  );
};
 