import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Search from "/src/components/helpers/ExposedSearch/ExposedSearch";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  NavLink,
  DropdownMenu,
} from "reactstrap";


 
const Header = ({currentLang}) => {
  return (
    <Navbar color="light" expand="md" light className="main-navbar">
      <NavbarBrand href="/">Propal PWA Playground</NavbarBrand>
      <NavbarToggler onClick={function noRefCheck() {}} />
      <Collapse navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <NavLink href="/articles">Articles</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/events">Events</NavLink>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              FakeOptions
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Search targeturl="/events?search=" />
        {currentLang !== "pl" && <NavLink href="/pl">Polish</NavLink>}
        {currentLang !== "en" && <NavLink href="/">English</NavLink>}
        {currentLang !== "fr" && <NavLink href="/fr">French</NavLink>}
      </Collapse>
    </Navbar> 
  );
};

export default Header;
